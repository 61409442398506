const SPACE_DATA = {
  "u6983821":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/source/pk/resource/j-smcm-z/openjs/t.js", space_src: "baidujs", space_type: "inters", space_id: "u6983821"}],
  "u6983823":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/site/r-ml/source/uo/resource/eo/be.js", space_src: "baidujs", space_type: "native", space_id: "u6983823"}],
  "u6983822":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/common/qlk_tn/source/dn/common/aa.js", space_src: "baidujs", space_type: "native", space_id: "u6983822"}],
  "u6983824":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/production/snmvp/f/openjs/pc/resource/j.js", space_src: "baidujs", space_type: "native", space_id: "u6983824"}],
  "u6983825":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/site/ton/common/w/static/q_g/resource/qdl.js", space_src: "baidujs", space_type: "native", space_id: "u6983825"}],
  "u6983826":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/common/upo_x/r/static/h_reo.js", space_src: "baidujs", space_type: "inters", space_id: "u6983826"}],
  "u6983828":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/production/wr/production/qz/t-jtg/resource/t.js", space_src: "baidujs", space_type: "native", space_id: "u6983828"}],
  "u6983827":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/source/vqp_y/openjs/s/production/isf/source/r.js", space_src: "baidujs", space_type: "native", space_id: "u6983827"}],
  "u6983829":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/production/xs/ra/static/u/common/kuha.js", space_src: "baidujs", space_type: "native", space_id: "u6983829"}],
  "u6983830":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/production/pk-js/source/m/resource/cm-cq.js", space_src: "baidujs", space_type: "native", space_id: "u6983830"}],
};
export const SPACE_MAP = {
Home_inter:  SPACE_DATA["u6983821"],
Home_native:  SPACE_DATA["u6983823"],
Home_banner:  SPACE_DATA["u6983822"],
Detail_inter:  SPACE_DATA["u6983821"],
Detail_native:  SPACE_DATA["u6983823"],
Detail_banner:  SPACE_DATA["u6983822"],
List_native:  SPACE_DATA["u6983823"],
List_banner:  SPACE_DATA["u6983822"],
Result_inter:  SPACE_DATA["u6983821"],
Result_native:  SPACE_DATA["u6983823"],
Result_banner:  SPACE_DATA["u6983822"],
Result_native_center1:  SPACE_DATA["u6983824"],
Result_native_center2:  SPACE_DATA["u6983825"],
}